import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const recomednationModule: Module<any, any> = {
  namespaced: true,
  state: {
    recomendationList: {
      first: ''
    },
  },
  getters: {},
  mutations: {},
  actions: {
    async getRecomendationBySegment(context) {
      const url = "https://b24-7zji69.bitrix24site.ru/pustayastranitsa_lpre/";
      axios
        .get(url)
        .then((response) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(response.data, "text/html");

          const block = doc.querySelector(".landing-block-node-text-container");

          const htmlContainer = block ? block.outerHTML : "";
          console.log(htmlContainer)

          context.state.recomendationList.first = htmlContainer
        })
        .catch((error) => {
          console.error("Ошибка при получении страницы:", error);
        });
    },
  },
};

export default recomednationModule;
