<template>

    <div class="reactivation-form">
      <div class="reactivation-form__block">
        <div class="reactivation-form__title">
          Получатели из сегмента "{{ activeSegment.name }}"
        </div>
        <div class="reactivation-form__subtitle text-small">
          Клиент явно дал согласие на отправку информационно-рекламной рассылки
        </div>

        <div class="reactivation-select">
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv"
              id="rv01"
              name="radio0"
            ></inputTypeRadio>
            <span
              >Исключить клиентов которые уже участвуют в активной
              Реактивации</span
            >
          </label>
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv"
              id="rv02"
              name="radio0"
            ></inputTypeRadio>
            <span>Включить в Реактивацию всех клиентов сегмента</span>
          </label>
          <label for="" class="reactivation-select__item _ignor">
            <inputCheck></inputCheck>
            <span
              >Игнорировать
              <span style="font-weight: 900; font-style: italic"
                >несогласие</span
              >
              на отправку</span
            >
            <span class="text-small"
              >Отправлять тем, у кого нет согласий для отправки
              уведомлений</span
            >
          </label>
        </div>
      </div>
      <div class="reactivation-form__block">
        <div class="reactivation-form__title">Каналы отправки</div>
        <div class="reactivation-form__subtitle text-small">
          либо PUSH либо WhatsApp либо Email
        </div>

        <div class="reactivation-select">
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv1"
              id="rv1"
              name="radio1"
            ></inputTypeRadio>
            <span
              >Всем у кого есть мобильное приложение YPLACES, отправится
              PUSH</span
            >
          </label>
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv1"
              id="rv2"
              name="radio1"
            ></inputTypeRadio>
            <span>Всем отправить по WhatsApp</span>
          </label>
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv1"
              id="rv3"
              name="radio1"
            ></inputTypeRadio>
            <span>Всем у кого есть Email</span>
          </label>
        </div>
      </div>
      <div class="reactivation-form__block">
        <div class="reactivation-form__title">
          Запланировать старт Реактивации
        </div>
        <div class="reactivation-form__subtitle text-small">
          время в часовом поясе Europe/Moscow (UTC+03)
        </div>

        <div class="reactivation-inputs">
          <div class="reactivation-inputs__row">
            <span class="text-small">с</span>
            <div class="reactivation-inputs__col">
              <input type="time" class="default-input" />
              <span class="text-small">по</span>
              <input type="time" class="default-input" />
            </div>
            <div class="text-small reactivation-inputs__sub">
              Интервал в который разрешена рассылка
            </div>
          </div>

          <div class="reactivation-inputs__row">
            <span class="text-small">на</span>
            <div class="reactivation-inputs__col">
              <input type="date" class="default-input" />
              <span class="text-small">в</span>
              <input type="time" class="default-input" />
            </div>
          </div>
        </div>
      </div>
      <div class="reactivation-form__block _aims">
        <div class="reactivation-form__title">Считать достижением цели</div>
        <div class="reactivation-form__subtitle text-small">
          Отразится в отчете
        </div>

        <div class="reactivation-select mb-28px">
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv2"
              id="rv13"
              name="radio11"
            ></inputTypeRadio>
            <span>Клиент записался</span>
          </label>
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv2"
              id="rv14"
              name="radio11"
            ></inputTypeRadio>
            <span>Клиент пришёл</span>
          </label>
          <label for="" class="reactivation-select__item">
            <inputTypeRadio
              v-model="rv2"
              id="rv15"
              name="radio11"
            ></inputTypeRadio>
            <span>Клиент пришёл и оплатил</span>
          </label>
        </div>
        <div class="reactivation-inputs">
          <div class="reactivation-inputs__row">
            <span class="text-small">Отслеживать в течении</span>
            <div class="reactivation-inputs__col">
              <input
                type="number"
                placeholder="30"
                min="1"
                class="default-input _days"
              />
              <span class="text-small">дней с момента старта Реактивации </span>
            </div>
          </div>
          <div class="reactivation-inputs__row">
            <div class="text-small reactivation-inputs__or">или</div>
          </div>
        </div>
        <div class="reactivation-select _last">
          <label for="" class="reactivation-select__item _ignor">
            <inputCheck></inputCheck>
            <span>До следующей активности </span>
            <span class="text-small"
              >Вы всегда сможете завершить активность вручную в любое
              время</span
            >
          </label>
        </div>
      </div>
      <div class="reactivation-form__block">
        <button class="reactivation-btn btn" @click="createMessage">
          Создать текст сообщения
          <span class="reactivation-btn__number"
            ><img src="~@/assets/images/letter.svg" alt="" /><span
              >100</span
            ></span
          >
        </button>
      </div>
    </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import { computed, onUnmounted, ref } from "vue";

const store = useStore();

const activeSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

function createMessage() {
  store.state.segments.reactivationCreateStep = 1
  window.scrollTo(0,0)
}

onUnmounted(() => {
  store.state.segments.currentSelectSegment = null
})
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

$grid-col: 1.8fr 5fr 1fr;
.reactivation-form {
  border: solid 1px #e1e3eb;
  max-width: 800px;
  border-radius: 12px;
  &__block {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-bottom: solid 1px #e1e3eb;
    display: grid;
    grid-template-columns: $grid-col;
    grid-template-rows: 25px 30px auto auto;

    .reactivation-btn {
      grid-column: 2 / 3;
      font-size: 14px;
      background: $yellowBtnBg;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 300px;
      min-height: 35px;
      font-weight: 500;
      justify-content: space-around;
      cursor: pointer;

      &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 2px;

        span {
          transform: translateY(1.5px);
        }
      }
    }

    &._aims {
      grid-template-rows: 25px 30px auto auto auto;
    }

    &:first-child {
      padding-bottom: 65px;
      .reactivation-select {
        &__item:last-child {
          margin-top: 20px;
        }
      }
    }
    &:nth-child(4) {
      padding-bottom: 65px;
    }

    &:last-child {
      border-bottom: none;
      display: flex;
      flex-direction: column;
      align-items: end;
      padding: 21px 36px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    grid-row: 1;
    grid-column: 2 / 3;
  }

  &__subtitle {
    grid-row: 2;
    grid-column: 2 / 3;
  }

  &__btn {
    &._gray {
    }
  }
}
.reactivation-select {
  grid-row: 3;
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: $grid-col;
  grid-row-gap: 8px;

  &._last {
    grid-row: 5;
  }

  &__item {
    grid-column: 2 / -1;
    position: relative;
    display: flex;
    font-size: 14px;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    &._ignor .text-small {
      position: absolute;
      bottom: -25px;
      left: 29px;
    }
  }
}
.reactivation-inputs {
  grid-row: 4;
  grid-column: 1 / -1;

  &__row {
    display: grid;
    grid-template-columns: $grid-col;
    align-items: center;

    span:first-child {
      justify-self: end;
      padding-right: 17px;
    }
  }

  &__col {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  &__sub {
    grid-row: 2;
    grid-column: 2;
    margin-bottom: 15px;
    margin-top: 8px;
  }

  &__or {
    grid-row: 2;
    grid-column: 2;
    margin-top: 12px;
    margin-bottom: 11px;
  }
}

.text-small {
  color: #71757f;
  font-size: 13px;
}

.default-input._days {
  max-width: 70px;
}

.mb-28px {
  margin-bottom: 28px;
}
</style>
