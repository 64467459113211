<template>
  <div class="tit-h1" style="align-self: start">
    Расчитываем Сегменты <span class="pints">...</span>
  </div>
  <div class="start-settings">
    <h3 class="tit-h3 bold">
      Завершите настройку - Шаг {{ mainPageStep+=1 }} из 3
    </h3>

    <div class="start-settings__steps">
      <select-element v-if="mainPageStep === 0" v-model="usersList">
        <div class="subtitle">Кто будет получать отчеты на WhatsApp ?</div>
      </select-element>
      <stepOfferService v-else-if="mainPageStep === 1"></stepOfferService>
      <select-element v-if="mainPageStep === 2" v-model="usersList">
        <div class="subtitle">С кем выйти на связь для консультации ?</div>
      </select-element>
    </div>

    <button class="btn" @click="store.commit('setMainPageStep', mainPageStep+=1)">Далее</button>
  </div>
</template>

<script setup lang="ts">
import stepOfferService from "@/components/main-page/start-steps/stepOfferService.vue";
import stepSetConsultationContact from "@/components/main-page/start-steps/stepSetConsultationContact.vue";
import selectElement from "@/components/UI/inputs/select-elements.vue";
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";

const store = useStore()

const mainPageStep = computed<number>(() => store.state.mainPageStep);

const usersList = ref<TypeFilialUserData[]>([
  {
    name: "Пользователь 1",
    value: false,
    id: 1,
  },
  {
    name: "Пользователь 2",
    value: false,
    id: 2,
  },
  {
    name: "Пользователь 3",
    value: false,
    id: 3,
  },
]);
</script>

<style lang="scss" scoped>
.pints {
  display: inline-block;
  font-size: 30px;
  clip-path: inset(0 1.2ch 0 0);
  animation: l 1s steps(4) infinite;
}
@keyframes l {
  to {
    clip-path: inset(0 -0.4ch 0 0);
  }
}
.start-settings {
  max-width: 450px;
  width: 100%;
  margin-top: 73px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 34px;
  }

  .subtitle {
    margin-bottom: 34px;
  }

  &__steps {
    width: 100%;
    text-align: center;
  }

  .btn {
    margin-top: 34px;
    align-self: flex-end;
    min-width: 134px;
  }
}
</style>
