import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const clientsModule: Module<any, any> = {
  namespaced: true,
  state: {
    currentClientPage: {},
    clientPageLoading: false,
  },
  getters: {},
  mutations: {
    clearClientPages(state) {
      state.currentClientPage = {}
    }
  },
  actions: {
    async getClientsForSegmentByPage(
      context,
      payload: { segmentIndex: string; page: number }
    ) {
      context.state.clientPageLoading = true
      return await axios({
        url: "https://rfm.botzavod.com/api/company-segment-clients",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
          segment_id: payload.segmentIndex,
          page: payload.page.toString(),
        },
        method: "GET",
      }).then((res: AxiosResponse) => {
        context.state.clientPageLoading = false
        context.state.currentClientPage = res.data;

        if (context.state.currentClientPage.segment_clients) {
          context.state.currentClientPage.segment_clients.forEach((client:any) => {
            client.selected = false;
          });
        }
      });
    },
  },
};

export default clientsModule;
