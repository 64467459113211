<template>
  <div class="reactivation-template">
    <div class="reactivation-template__header">
      <div class="reactivation-template__title">Редактор шаблонов</div>
      <button class="btn _gray" @click="closeReactivation">
        Закрыть без изменений
      </button>
    </div>
    <div class="reactivation-template__block">
      <div class="reactivation-template__warn">
        <span>ВАЖНО!!!</span> Персонализируйте ваши сообщения, чтобы избежать
        блокировок
      </div>

      <reactivationTemplateForm></reactivationTemplateForm>
    </div>
    <div class="reactivation-template__block">
      <reactivationTestForm></reactivationTestForm>
    </div>
    <div class="reactivation-template__block">
      <button
        class="btn _gray"
        @click="store.state.segments.reactivationCreateStep = 0"
      >
        Назад
      </button>
      <button class="btn _gray">Отправить без тестирования</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import reactivationTemplateForm from "@/components/reactivation/reactivationTemplateForm.vue";
import reactivationTestForm from "@/components/reactivation/reactivationTestForm.vue";
import { computed, onUnmounted, reactive, ref } from "vue";

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

function inputRedaction(channel, event) {
  console.dir(event);
}

let activeChannel = ref("Push");

const templates = reactive({
  Push: { text: "" },
  WhatsApp: { text: "" },
});

function closeReactivation() {
  store.state.segments.currentSelectSegment = null;
  store.state.segments.currentSettingSegment = null;
}
</script>

<style lang="scss" scoped>
.reactivation-template {
  border: 1px solid #e5e7ed;
  border-radius: 12px;
  max-width: 796px;

  &__header {
    border-bottom: 1px solid #e5e7ed;
    padding: 40px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
  }

  &__warn {
    font-weight: 500;
    font-size: 11px;
    color: #dd6068;
    padding-left: 60px;
  }
  &__block {
    border-bottom: 1px solid #e5e7ed;
    padding-bottom: 40px;
  }

  &__block:last-child {
    display: flex;
    justify-content: end;
    padding: 35px 40px;
    gap: 70px;
  }
}
</style>
