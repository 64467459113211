<template>
  <div class="reactivation-test">
    <div class="reactivation-test__title tit-h3 fw-500">
      Обязательно протестируйте шаблон
    </div>
    <div class="text-small">прежде чем запустить рассылку</div>

    <input type="text" class="default-input" placeholder="Номер телефона тестировщика" />

    <button class="btn">Отправить тест</button>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";

const store = useStore();
</script>

<style lang="scss" scoped>
.reactivation-test {
  display: flex;
  flex-direction: column;
  max-width: 364px;
  margin: 0 auto;
  padding-top: 60px;
  gap: 8px;
  &__title {
  }

  .default-input {
    margin-bottom: 15px;
  }

  .text-small {
    margin-bottom: 15px;
  }
}
</style>
