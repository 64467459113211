<template>
  <div class="tariffs">
    <div class="tariffs__status">
      <tarifStatus v-if="currentTarif"></tarifStatus>
    </div>

    <div class="tariffs-list" v-if="allTariffs">
      <tarifCard
        v-for="tarif in allTariffs"
        :key="tarif"
        :tarif="tarif"
      ></tarifCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import tarifCard from "@/components/tariffs/tarifCard.vue";
import tarifStatus from '@/components/tariffs/tarifStatus.vue'
import { computed } from "vue";

const store = useStore();

const allTariffs = computed(() => store.state.tarif.allTariffs);

const currentTarif = computed(() => store.state.tarif.currentTarif);
</script>

<style lang="scss" scoped>
.tariffs {
  &__title {
    margin-bottom: 30px;
    font-weight: 500;
  }

  &__status {
    margin-bottom: 31px;
  }
}
.tariffs-list {
  display: flex;
  gap: 83px;
}
</style>
