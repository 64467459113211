<template>
  <div class="main-page">
    <startSettings v-if="mainPageStep <= 2"></startSettings>
    <segmentsTable v-else></segmentsTable>
  </div>
</template>

<script setup lang="ts">
import startSettings from "@/components/main-page/startSettings.vue";
import segmentsTable from "@/components/main-page/segments/segmentsTable.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const mainPageStep = computed(() => store.state.mainPageStep);
</script>

<style scoped lang="scss">
.main-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
