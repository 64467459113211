<template>
  <!-- Я хз как тут что работает, этот компонент писал chatGPT -->
  <div class="template-form">
    <div class="template-form__channels">
      <div
        class="template-form__channel _active"
      >
        Выбранный канал
      </div>
    </div>
    <div class="template-form__redactor">
      <div
        class="template-form__redaction"
        contenteditable="true"
        @input="inputRedaction(channel, $event)"
        @focus="saveSelection"
        @keyup="saveSelection"
        @mouseup="saveSelection"
        :key="channel"
      ></div>
      <transition name="fade">
        <div class="template-form__variables" v-if="variablesVisible">
          <button
            class="template-form__variable"
            v-for="variable in variables"
            :key="variable.id"
            :style="`background-color:${variable.color} `"
            @click="addVariable(variable.template)"
          >
            {{ variable.name }}
          </button>
        </div>
      </transition>
    </div>
    <div class="template-form__footer">
      <button
        class="template-form__btn"
        @click="variablesVisible = !variablesVisible"
      >
        Переменные
      </button>
      <emojiButton
        class="template-form__btn _emoji"
        @emojiSelected="(emoji) => insertContentAtCursor(emoji.emoji)"
        ><img src="~@/assets/images/emoji-open.svg" alt=""
      /></emojiButton>
      <button class="template-form__btn" @click="clearEditor">Очистить</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import { computed, reactive, ref } from "vue";

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

let variablesVisible = ref(false);

const variables = [
  {
    template: "CLIENT_NAME",
    color: "rgb(92, 195, 198)",
    name: "Имя клиента",
    id: 1,
  },
  {
    template: "TEST_VARIABLE",
    color: "red",
    name: "Тестовая переменная",
    id: 2,
  },
];

let activeChannel = ref("Push");

const templates = reactive({
  Push: { text: "" },
  WhatsApp: { text: "" },
});

let savedRange = null;

function saveSelection() {
  const sel = window.getSelection();
  if (sel.rangeCount > 0) {
    savedRange = sel.getRangeAt(0);
  }
}

function restoreSelection() {
  const sel = window.getSelection();
  if (savedRange) {
    sel.removeAllRanges();
    sel.addRange(savedRange);
  }
}

function wrapVariables(text) {
  variables.forEach((variable) => {
    const regex = new RegExp(`%${variable.template}%`, "gi");
    text = text.replace(regex, (match) => {
      // Проверяем, не обернута ли переменная уже в span
      if (!match.includes('<span class="template-form-variable"')) {
        return `<span class="template-form-variable" style="color: ${variable.color};">${match}</span>`;
      }
      return match;
    });
  });
  return text;
}

function unwrapVariables(text) {
  const div = document.createElement("div");
  div.innerHTML = text;
  div.querySelectorAll(".template-form-variable").forEach((span) => {
    span.replaceWith(span.textContent);
  });
  return div.innerHTML;
}

function inputRedaction(channel, event) {
  const content = event.target.innerHTML;
  const unwrappedContent = unwrapVariables(content);
  const wrappedContent = wrapVariables(unwrappedContent);
  event.target.innerHTML = wrappedContent;
  placeCaretAtEnd(event.target);
}

function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

function insertContentAtCursor(content) {
  restoreSelection();
  const sel = window.getSelection();
  const redaction = document.querySelector(".template-form__redaction");

  if (sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    if (
      range.startContainer === redaction ||
      redaction.contains(range.startContainer)
    ) {
      range.deleteContents();
      const node = document.createElement("span");
      node.innerHTML = content;
      range.insertNode(node);
      range.setStartAfter(node);
      range.setEndAfter(node);
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      redaction.innerHTML += content;
      placeCaretAtEnd(redaction);
    }
  } else {
    redaction.innerHTML += content;
    placeCaretAtEnd(redaction);
  }
  saveSelection();
}

function addVariable(template) {
  const variable = variables.find((v) => v.template === template);
  const content = `<span class="template-form-variable" style="color: ${variable.color};">%${template}%</span>`;
  insertContentAtCursor(content);
  variablesVisible.value = false;
}

function clearEditor() {
const redaction = document.querySelector(".template-form__redaction");
redaction.innerHTML = "";
savedRange = null;
}
</script>

<style lang="scss" scoped>
.template-form {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  &__channels {
    display: flex;
    gap: 5px;
  }

  &__channel {
    position: relative;
    background: #f0f3f8;
    padding: 16px 24px;
    border-radius: 12px;
    z-index: 1;
    translate: 0 10px;
    font-size: 14px;
    color: #6d6d6d;
    cursor: pointer;

    transition: 0.1s;

    &._active {
      color: #000;
      translate: 0 5px;
      border-radius: 12px 12px 0 0;
      background: #f6f9fc;
    }
  }

  &__redactor {
    position: relative;
  }

  &__redaction {
    position: relative;
    z-index: 2;
    background: #f6f9fc;
    min-height: 279px;
    font-size: 16px;
    line-height: 187%;
    padding: 40px;
    outline: none;
  }

  &__variables {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 40px;
    display: flex;
    gap: 10px;
    align-items: start;
    background: #f6f9fc;
  }
  &__variable {
    padding: 5px 10px;
    border-radius: 49px;
    color: #fff;
    cursor: pointer;
  }

  &__footer {
    background: #f0f3f8;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    padding: 15px;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(21, 21, 21, 0.15);
    background: #fff;
    border-radius: 12px;
    min-height: 44px;
    color: #6d6d6d;
    padding: 0 24px;
    cursor: pointer;

    &._emoji {
      padding: 0 16px;

      img {
        transform: translate(0.5px, 1px);
        cursor: pointer;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
.template-form-variable {
  text-transform: uppercase;
  display: inline-block;
}
</style>
