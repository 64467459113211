<template>
  <div class="select-elements">
    <slot></slot>
    <div class="select-elements__list">
      <div
        class="select-elements__item"
        v-for="(item, index) in props.modelValue"
        :key="index"
        @click="selectedElement(index)"
      >
        <span class="select-elements__label">{{ item.name }}</span>
        <span class="select-elements__value" :class="'select-elements-' + item.value"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TypeFilialUserData } from "@/types/typesOfApi";
import { defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue']);

type props = {
  modelValue: TypeFilialUserData[];
};
/* eslint-disable-next-line */
const props = defineProps<props>();

function selectedElement(index: number) {
  const currentSelectedValue = !props.modelValue[index].value
  const newModelValue = [...props.modelValue]
  newModelValue[index].value = currentSelectedValue
  emit('update:modelValue', newModelValue)
}
</script>

<style lang="scss" scoped>
.select-elements {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item {
    display: flex;
    justify-content: center;
    gap: 23px;
    cursor: pointer;
    user-select: none;
    span {
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 10px;
    }
  }

  &__label {
    padding: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, .6);
    text-align: left;
    flex-grow: 1;
  }

  &__value {
    width: 49.7px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '+';
      font-size: 34px;
      position: absolute;
      top: 52%;
      left: 49%;
      line-height: 0;
      font-weight: 300;
      color: #DF0000;
      transform: translate(-40%, -50%) rotate(-45deg);
      transition: .1s;
    }
    &.select-elements-true:after {
          transform: translate(-43%, -50%) rotate(0deg);
    top: 53%;
      color: #00BA3F;
    }
  }
}
</style>
