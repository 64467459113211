<template>
  <div class="tarif-status " :class="isTarifActive ? `_active` : ''">{{ isTarifActive ? endDateStr : 'Сервис не активен, приобретите лицензию' }}</div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import months from '@/assets/months.ts'

const store = useStore();

const currentTarif = computed(() => store.state.tarif.currentTarif);

const endDateStr = computed(() =>
  formatEndDate(currentTarif.value.license_expiry)
);

const isTarifActive = computed(() => currentTarif.value.active)

function formatEndDate(endDateStr: string): string {

  const [year, month, day] = endDateStr.split("-").map(Number);

  const monthName = months[month-1].genitive;

  return `Сервис активен до: ${day} ${monthName} ${year}`;
}
</script>

<style lang="scss" scoped>
.tarif-status {
  font-weight: 500;
  padding: 17px 23px;
  border-radius: 9px;
  display: inline-block;
  background: #fac1c4;

  &._active {
    background: #c3e5a7;
  }
}
</style>
