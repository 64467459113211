<template>
  <div class="reactivation-create">
    <div class="reactivation-create__title">
      12:31 - 10.21.2024 | Сегмент {{ activeSegment.name }} | 100 клиентов
    </div>

    <reactivationCreateForm v-show="reactivationCreateStep === 0"></reactivationCreateForm>
    <reactivationTemplate v-show="reactivationCreateStep === 1"></reactivationTemplate>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import reactivationCreateForm from "@/components/reactivation/reactivationCreateForm.vue";
import reactivationTemplate from "@/components/reactivation/reactivationTemplate.vue";
import { computed, onUnmounted, ref } from "vue";

const store = useStore();

const reactivationCreateStep = computed(() => store.state.segments.reactivationCreateStep)

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

onUnmounted(() => {
  store.state.segments.currentSelectSegment = null;
  store.state.segments.reactivationCreateStep = 0;
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

$grid-col: 1.8fr 5fr 1fr;
.reactivation-create {
  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 28px;
    margin-bottom: 16px;
  }
}


.default-input._days {
  max-width: 70px;
}

.mb-28px {
  margin-bottom: 28px;
}
</style>
