import { IAuthState } from "./authModuleTypes";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const authModule: Module<IAuthState, any> = {
  namespaced: true,
  state: {
    bearerToken: null,
    authParams: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async registraion(context) {
      context.state.authParams = location.search;
      const url: string =
        `https://rfm.botzavod.com/api/registration` + location.search;

      return await axios({
        method: "GET",
        url: url,
      }).then((res: AxiosResponse) => {
        console.log(res);
        context.state.bearerToken = res.data.bearer_token;
      });
    },
  },
};

export default authModule;
