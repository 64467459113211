export default [
  { full: "Январь", short: "Янв", index: 0, genitive: "Января" },
  { full: "Февраль", short: "Фев", index: 1, genitive: "Февраля" },
  { full: "Март", short: "Мар", index: 2, genitive: "Марта" },
  { full: "Апрель", short: "Апр", index: 3, genitive: "Апреля" },
  { full: "Май", short: "Мая", index: 4, genitive: "Мая" },
  { full: "Июнь", short: "Июн", index: 5, genitive: "Июня" },
  { full: "Июль", short: "Июл", index: 6, genitive: "Июля" },
  { full: "Август", short: "Авг", index: 7, genitive: "Августа" },
  { full: "Сентябрь", short: "Сен", index: 8, genitive: "Сентября" },
  { full: "Октябрь", short: "Окт", index: 9, genitive: "Октября" },
  { full: "Ноябрь", short: "Ноя", index: 10, genitive: "Ноября" },
  { full: "Декабрь", short: "Дек", index: 11, genitive: "Декабря" },
];
