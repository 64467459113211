<template>
  <div class="subtitle">
    Желаете узнать об услуге - <span class="text-yellow">Мой Маркетолог</span>  подробнее? 
  </div>

  <div class="radio-list">
    <input-type-radio id="radioTrue" name="radio" v-model="radioValue" value="0">
      <span class="radio-list__label">Да - свяжитесь со мной</span>
    </input-type-radio>
    <input-type-radio id="radioFalse" name="radio" v-model="radioValue" value="1">
      <span class="radio-list__label">Нет - буду работать самостоятельно</span>
    </input-type-radio>
  </div>
</template>

<script lang="ts" setup>
import inputTypeRadio from '@/components/UI/inputs/input-type-radio.vue'
import { ref } from 'vue'

let radioValue = ref<'0' | '1'>('0')
</script>

<style lang="scss" csoped>
.radio-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 36px;

  margin: 0 auto;
  max-width: 400px;

  &__label {
    margin-left: 24px;
    font-weight: 600;
  }
}

</style>