<template>
  <div class="radio-group">
    <input
      class="radio-input"
      type="radio"
      :id="props.id"
      :value="props.value"
      :name="props.name"
      @change="updateValue"
      :checked="props.modelValue === props.value"
    />
    <label :for="id" class="radio-label">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits } from "vue";
type TypeProps = {
  id: string;
  modelValue: string;
  name: string;
  value: string;
};
/* eslint-disable-next-line */
const props = defineProps<TypeProps>();

const emit = defineEmits(["update:modelValue"]);

function updateValue(event: any) {
  console.log(event);
  emit("update:modelValue", event.target.value);
}
</script>

<style lang="scss" scoped>
.radio-input {
  display: none;
}

.radio-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.radio-label::before {
  content: "";
  background: url(~@/assets/images/radio-default.svg) no-repeat center / 102%;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.radio-input:checked + .radio-label::before {
  content: "";
  background: url(~@/assets/images/radio-selected.svg) no-repeat center / 102%;
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>
