<template>
  <label :class="{ checked: isChecked }" class="custom-checkbox">
    <input type="checkbox" :checked="isChecked" @change="handleChange" />
    <span class="checkmark"></span>
    <span class="label-text">{{ label }}</span>
  </label>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from "vue";

const props = defineProps<{
  modelValue: boolean;
  label?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const isChecked = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isChecked.value = newValue;
  }
);

const handleChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit("update:modelValue", target.checked);
};
</script>

<style scoped>
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  background: url(~@/assets/images/checkbox-default.svg) no-repeat center /100%;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background: url(~@/assets/images/checkbox-active.svg) no-repeat center /100%;
}

.custom-checkbox .label-text {
  font-size: 16px;
}
</style>
