export default function getPayStatusDescr(status: string): string {
  const descriptions: { [key: string]: string } = {
      "NEW": "Платеж создан, перейдите к оплате",
      "FORM_SHOWED": "Платеж создан.",
      "AUTHORIZING": "Платеж обрабатывается, пожалуйста, подождите.",
      "AUTHORIZED": "Платеж авторизован, деньги заморожены.",
      "CONFIRMING": "Идет подтверждение платежа.",
      "CONFIRMED": "Оплата прошла успешно",
      "REFUNDING": "Возврат платежа обрабатывается.",
      "ASYNC_REFUNDING": "Обработка возврата по QR в процессе.",
      "PARTIAL_REFUNDED": "Частичный возврат завершен успешно.",
      "REFUNDED": "Полный возврат завершен успешно.",
      "CANCELED": "Платеж отменен.",
      "DEADLINE_EXPIRED": "Время для завершения платежа истекло.",
      "ATTEMPTS_EXPIRED": "Превышено количество попыток открытия формы.",
      "REJECTED": "Банк отклонил платеж.",
      "AUTH_FAIL": "Ошибка платежа или не пройдена проверка 3D-Secure."
  };

  return descriptions[status] || "Неизвестный статус платежа.";
}