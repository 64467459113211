<template>
  <div class="tarif-card" :class="{ _disabled: isDisabled }">
    <div
      class="tarif-card__finish"
      :class="`_${currentTarif.free_months}`"
      v-if="!isCurrentTarifPayment"
    >
      {{ mainMonths }} {{ getMonthStr(mainMonths) }}

      <span class="tarif-card__flag" v-if="+currentTarif.free_months"
        >+ {{ currentTarif.free_months }} в подарок</span
      >
    </div>

    <div class="tarif-card__finish" style="justify-content: center" v-else>
      <span v-if="awaitingStatusesArr.includes(currentPaymentStatus)"
        >Ожидаем оплату</span
      >
      <span v-else-if="currentPaymentStatus === 'CONFIRMED'"
        >Оплата прошла успешно</span
      >
      <span v-else-if="failedStatusesArr.includes(currentPaymentStatus)"
        >Ошибка оплаты</span
      >

      <span
        class="small-loader tarif-card__loader"
        v-if="awaitingStatusesArr.includes(currentPaymentStatus)"
      ></span>
      <img
        class="tarif-card__icon _success"
        :src="require('@/assets/images/success-icon.svg')"
        alt=""
        v-else-if="currentPaymentStatus === 'CONFIRMED'"
      />
      <img
        class="tarif-card__icon _failed"
        :src="require('@/assets/images/failed-icon.svg')"
        alt=""
        v-else-if="failedStatusesArr.includes(currentPaymentStatus)"
      />
    </div>

    <div class="tarif-card__info">
      <div class="tarif-card__name">Всё включено / {{ allMonthStr }}</div>
      <div class="tarif-card__price">
        {{ priceMonth.toLocaleString() }} ₽/мес
      </div>
      <div class="tarif-card__btn">
        <button
          class="btn _border-raduis-9px fw-600"
          @click="createPayment"
          v-if="!isCurrentTarifPayment"
        >
          Оплатить
        </button>
        <a
          class="btn _border-raduis-9px _payment fw-600"
          :href="currentPayment.PaymentURL"
          target="_blank"
          v-else-if="currentPayment && !isPayConfirming"
          >Перейти к оплате</a
        >
        <button class="btn _payment fw-600" @click="resetPayInfo" v-else>Вернуться</button>
      </div>
      <div class="tarif-card__allprice" v-if="allMonth > 1 && !isCurrentTarifPayment">
        {{ priceFull }}₽ за {{ allMonthStr }}
      </div>

        <span class="tarif-pay-info__row" v-if="isCurrentTarifPayment && !isPayConfirming">
          <span class="fw-600">Времени осталось:</span>
          <payTimer
            class="tarif-pay-info__timer"
            :deathDate="currentPayment.RedirectDueDate"
          ></payTimer>
        </span>

    

      <div class="tarif-card__options" >
        <div
          class="tarif-card__option"
          v-for="option in tarifOptions"
          :key="option"
        >
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TypeTarif } from "@/store/modules/tariffs/tarifModuleTypes";
import { computed } from "vue";
import { useStore } from "vuex";
import getDeclension from "@/assets/getDeclension";
import getPayStatusDescr from "@/assets/getPayStatusDescr";
import payTimer from "@/components/UI/pay-taimer.vue";
type typeProps = {
  tarif: TypeTarif;
};
const store = useStore();
/* eslint-disable-next-line */
const props = defineProps<typeProps>();

const currentTarif = computed(() => props.tarif);

const currentPayment = computed(() => store.state.payment.currentPayment);

const currentPaymentStatus = computed(
  () => store.state.payment.currentPaymentStatus
);

function resetPayInfo() {
  store.state.payment.currentPayment = null;
  store.state.payment.currentPaymentStatus = null;
  store.state.payment.currentPaymentTarifId = null;
  if (!store.state.payment.getStatusInterval) {
    clearInterval(store.state.payment.getStatusInterval)
  }
}

const isPayConfirming = computed(() => currentPaymentStatus.value === 'CONFIRMED')

const currentPaymentTarifId = computed(
  () => store.state.payment.currentPaymentTarifId
);

const payStatusDescr = computed(() =>
  getPayStatusDescr(currentPaymentStatus.value)
);

const isDisabled = computed(() => {
  if (currentPaymentTarifId.value) {
    if (props.tarif.tariff_id === currentPaymentTarifId.value) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
});

const failedStatusesArr = [
  "REFUNDING",
  "ASYNC_REFUNDING",
  "PARTIAL_REFUNDED",
  "REFUNDED",
  "CANCELED",
  "DEADLINE_EXPIRED",
  "ATTEMPTS_EXPIRED",
  "REJECTED",
  "AUTH_FAIL",
];
const awaitingStatusesArr = [
  "NEW",
  "FORM_SHOWED",
  "AUTHORIZING",
  "AUTHORIZED",
  "CONFIRMING",
];

const isCurrentTarifPayment = computed(
  () => props.tarif.tariff_id === currentPaymentTarifId.value
);

function getMonthStr(number: number): string {
  return getDeclension(number, "месяц", "месяца", "месяцев");
}
const priceMonth = computed(() => +currentTarif.value.price_month);
const priceFull = computed(() => +currentTarif.value.price_full);
const allMonth = computed(() => +currentTarif.value.period_months);
const mainMonths = computed(
  () => currentTarif.value.period_months - +currentTarif.value.free_months
);

const allMonthStr = computed(
  () => allMonth.value + " " + getMonthStr(currentTarif.value.period_months)
);
const tarifOptions = computed(() => currentTarif.value.options.split(","));

function createPayment(): void {
  store.dispatch("payment/createPaymentForTarif", props.tarif.tariff_id);
}
</script>

<style lang="scss" scoped>
.tarif-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 284px;
  gap: 27px;
  height: 100%;

  &._disabled {
    pointer-events: none;
    filter: blur(6px);
  }

  &__finish {
    border: solid 1px #e1e3eb;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 13px 18px 13px 16px;
    border-radius: 9px;
    font-weight: 600;
    gap: 9px;
    min-height: 59px;
    position: relative;

    &._0 {
      justify-content: center;
    }
  }

  &__flag {
    background: #ededfc;
    color: #583cf1;
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 4px;
  }

  &__loader {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
  }

  &__icon {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  &__info {
    border: solid 1px #e1e3eb;
    border-radius: 9px;
    padding: 24px 18px 34px 18px;
    min-height: 410px;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 17px;
    border-bottom: solid 1px #e1e3eb;
    margin-bottom: 27px;
  }

  &__price {
    font-weight: 500;
    text-align: center;
    font-size: 36px;
    margin-bottom: 27px;
  }

  &__btn {
    .btn {
      width: 100%;
      display: block;

      &._payment {
        background: #634ce4;
        color: #fff;
      }
    }
    margin-bottom: 13px;
  }

  &__allprice {
    font-size: 16px;
    color: #a1a2a2;
    font-weight: 500;
    padding-left: 11px;
  }

  &__options {
    margin-top: auto;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 32px;
  }

  &__option {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -18px;
      transform: translateY(-50%);
      background: url(~@/assets/images/check-icon.svg) no-repeat center / cover;
      width: 9px;
      height: 7px;
    }
  }
}
.tarif-pay-info {
  border-top: solid 1px #e1e3eb;
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  &__row {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  &__timer {
  }

  &__descr {
    font-size: 14px;
    text-align: center;
  }
}
</style>
