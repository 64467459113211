import axios, { AxiosResponse } from "axios";
import { createStore } from "vuex";
import { IState } from "./types";
import authModule from "./modules/auth/authModule";
import segmentsModule from "./modules/segments/segmentsModule";
import tarifModule from "./modules/tariffs/tariffsModule";
import paymentModule from './modules/payment/paymentModule'

export default createStore<IState>({
  state: {
    mainPageStep: 3,
    salon_id: null,
    mainLoader: true,
  },
  getters: {},
  mutations: {
    setMainPageStep(state: IState, step: number) {
      state.mainPageStep = step;
    },
    setMainLoader(state: IState, value: boolean) {
      state.mainLoader = value
    }
  },
  actions: {},
  modules: {
    auth: authModule,
    segments: segmentsModule,
    tarif: tarifModule,
    payment: paymentModule,
  },
});
