<template>
  <div
    class="segment-settings"
    :class="currentSettingsSegment.key === 'awaiting_segments' ? '_await' : ''"
  >
    <div class="settings-menu" v-if="false">
      <button
        class="settings-menu__item menu-link"
        :class="currentShowBlockIndex === 0 ? `active` : ''"
        @click="currentShowBlockIndex = 0"
      >
        Рекомендации по сегменту
      </button>
      <button
        class="settings-menu__item menu-link"
        :class="currentShowBlockIndex === 1 ? `active` : ''"
        @click="currentShowBlockIndex = 1"
      >
        Клиенты сегмента
      </button>
    </div>
    <div
      class="segments-nav"
      v-if="currentSettingsSegment.key === 'awaiting_segments' && false"
    >
      <button
        v-for="item in segmentAwaitingItems"
        :key="item.key"
        @click="setAwaitSetting(item.key)"
        class="segments-nav__item menu-link"
        :class="
          currentSelectSegment && currentSelectSegment.key === item.key
            ? 'active'
            : ''
        "
      >
        {{ item.name }}
      </button>
    </div>

    <div class="segment-settings__container">
      <segmentRecomendation
        :segment="currentSelectSegment"
        v-if="currentSelectSegment && currentShowBlockIndex === 0"
      ></segmentRecomendation>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import { computed, ref } from "vue";

import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";

const currentSettingsSegment = computed(
  () => store.state.segments.currentSettingSegment
);

const currentSelectSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const currentShowBlockIndex = ref(0);

const store = useStore();

const awaitingSegments = computed(
  () => store.state.segments.modifySegments.awaitingSegments
);
const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

const segmentAwaitingItems = [
  {
    name: "Все",
    key: "awaiting_segments",
  },
  {
    name: "Новички",
    key: "expect_newcomer",
  },
  {
    name: "Растущие",
    key: "expect_growing",
  },
  {
    name: "Чемпионы",
    key: "expect_champion",
  },
];

function setAwaitSetting(key) {
  if (key === "awaiting_segments") {
    store.state.segments.currentSelectSegment = awaitSegment.value;
  } else {
    store.state.segments.currentSelectSegment = awaitingSegments.value.find(
      (i) => i.key === key
    );
  }
}
</script>

<style lang="scss" scoped>
.segment-settings {
  display: grid;
  // grid-template-rows: 56px 40px auto;
  max-height: 100%;
  overflow: auto;
  &__container {
    grid-row: 4;
  }

  &._await {
    // grid-template-rows: 56px 29px 45px auto;

    .settings-menu__item {
      top: -2px;
    } 
  }
}
.settings-info {
  grid-row: 1;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 20px;
  &__number {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
  }
}
.settings-menu {
  grid-row: 2;

  display: grid;
  grid-template-columns: auto auto 1fr;
  border-bottom: 1px solid #e5e7ed;
  &__item {
    padding: 0 20px 15px 20px;
    cursor: pointer;
    position: relative;
    top: 1px;
  }
}
.segments-nav {
  grid-row: 3;
  border-bottom: 1px solid #e5e7ed;

  &__item {
    padding: 13px 20px 15px 20px;
    cursor: pointer;
    position: relative;
    top: 2px;
    font-size: 14px;
  }
}
</style>
