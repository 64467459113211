<template>
  <header class="header">
    <div
      v-for="item in navList.filter((i) => i.show)"
      :key="item.name"
      class="menu-link"
    >
      <router-link
        v-if="item.isRouterLink"
        :to="item.route"
        class="menu-link__item"
        >{{ item.name }}</router-link
      >
      <a
        v-if="!item.isRouterLink"
        :href="item.route"
        class="menu-link__item"
        target="_blank"
        >{{ item.name }}</a
      >
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

type navItem = {
  name: string;
  route: string;
  index: number;
  isRouterLink: boolean;
};

const store = useStore();

const currentTarif = computed(() => store.state.tarif.currentTarif);

const navList = computed<navItem[]>(() => {
  let result = [
    {
      name: "Поддержка",
      route: "https://t.me/Segment_support_bot",
      isRouterLink: false,
      index: 5,
      show: true,
    },
    {
      name: "Тарифы",
      route: "/tariffs",
      isRouterLink: true,
      index: 6,
      show: true,
    },
  ];

  if (currentTarif.value && currentTarif.value.active) {
    result = [
      ...result,
      {
        name: "Сегменты",
        route: "/",
        isRouterLink: true,
        index: 0,
        show: true,
      },
      {
        name: "Реактивация",
        route: "/reactivation",
        isRouterLink: true,
        index: 1,
        show: true,
      },
      {
        name: "Смарт",
        route: "/smart",
        isRouterLink: true,
        index: 2,
        show: false,
      },
      {
        name: "Отчеты",
        route: "/reports",
        isRouterLink: true,
        index: 3,
        show: false,
      },
      {
        name: "Настройки",
        route: "/settings",
        isRouterLink: true,
        index: 4,
        show: false,
      },
    ];
  }

  return result.sort((a, b) => a.index - b.index);
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.header {
  display: flex;
  border-bottom: 1px solid #e1e3eb;
  margin-bottom: 25px;
  .menu-link {
    &__item {
      padding: 14px 12px;
      font-size: 14px;

      transition: 0.3s;
      &:hover {
        background-color: #f7f9fc;
      }
    }
  }
}

.menu-link {
  a {
    display: block;
    color: inherit;
    text-decoration: none;
    color: #71757f;
    font-weight: 400;
    transition: 0.2s;
    position: relative;
  }
  &:after,
  a:after {
    content: "";
    height: 3px;
    width: 100%;
    position: absolute;
    background: $mainActiveColor;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s;
  }

  &.active,
  &.router-link-active,
  a.active,
  a.router-link-active {
    color: #000;
    font-weight: 500;
    &:after {
      opacity: 1;
    }
  }
}
</style>
