<template>
  <div class="segments-menu" v-if="modifySegments">
    <div class="segments-menu__inner">
      <segmentSettings v-if="activeSegment"></segmentSettings>
    </div>
  </div>
</template>

<script lang='ts' setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import segmentSettings from './segmentSettings'

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const allSegmentsArr = computed(() => {
  return [...modifySegments.value.dynamicSegments, modifySegments.value.didNotCome, modifySegments.value.outflow].sort((a, b) => a.index - b.index)
})

const activeSegment = computed(() => store.state.segments.currentSettingSegment)

</script>

<style lang='scss' scoped>
.segments-menu {
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;

  &__btns {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    gap:  32px
  }
}


</style>