import { IPaymentState, TypePaymentStatus } from "./paymentTypes";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const tarifModule: Module<IPaymentState, any> = {
  namespaced: true,
  state: {
    currentPayment: null,
    currentPaymentStatus: null,
    currentPaymentTarifId: null,
    getStatusInterval: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async createPaymentForTarif(context, tariff_id) {
      const formData = new FormData();
      
      context.commit('setMainLoader', true, {root: true})

      formData.append("salon_id", context.rootState.salon_id);
      formData.append("tariff_id", tariff_id);

      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/company-payment-init",
        data: formData,
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res: AxiosResponse) => {
        const payment = res.data
        context.state.currentPaymentTarifId = tariff_id;
        console.log(res);
        context.state.currentPayment = payment;

        context.dispatch("getPaymentStatus");
        context.state.getStatusInterval = setInterval(() => {
          context.dispatch("getPaymentStatus");
        }, 20000);

        context.commit('setMainLoader', false, {root: true})

        window.open(payment.PaymentURL, "_blank")
      });
    },
    async getPaymentStatus(context) {
      const paymentId = context.state.currentPayment
        ? context.state.currentPayment.PaymentId
        : "";

      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-company-payment-status",
        params: {
          salon_id: context.rootState.salon_id,
          PaymentId: paymentId,
        },
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res: AxiosResponse<{ status: TypePaymentStatus }>) => {
        console.log(res);
        const status = res.data.status;
        context.state.currentPaymentStatus = status;

        if (status === "CONFIRMED") {
          clearInterval(context.state.getStatusInterval!);

          context.dispatch("tarif/getCurrentTarif", null, { root: true }).then(companyLicense => {
            if (companyLicense.active) {
              const url = location.origin + '/' + context.rootState.auth.authParams

              location.href = url
            }
          });
        }

        return status;
      });
    },
  },
};

export default tarifModule;
