<template>
  <span>{{ formattedTime }}</span>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from "vue";

interface Props {
  deathDate: string;
}

const props = defineProps<Props>();

const formattedTime = ref<string>("00:00");
const intervalId = ref<number | null>(null);

const updateTimer = () => {
  const now = new Date();
  const deathDate = new Date(props.deathDate);
  const diffInSeconds = Math.floor(
    (deathDate.getTime() - now.getTime()) / 1000
  );

  if (diffInSeconds <= 0) {
    formattedTime.value = "00:00";
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
    }
    return;
  }

  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;
  formattedTime.value = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
};

onMounted(() => {
  updateTimer();
  intervalId.value = setInterval(updateTimer, 1000);
});

onUnmounted(() => {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
});

watch(
  () => props.deathDate,
  () => {
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
    }
    updateTimer();
    intervalId.value = setInterval(updateTimer, 1000);
  }
);
</script>
