<template>
  <div class="container" :class="{ _loader: mainLoaderState }">
    <segHeader></segHeader>
    <router-view></router-view>
  </div>
  <div class="main-loader" v-show="mainLoaderState"></div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import segHeader from "@/components/segHeader.vue";
import { onMounted, computed } from "vue";

const store = useStore();

const route = useRoute();
const router = useRouter();

const mainLoaderState = computed(() => store.state.mainLoader);

onMounted(() => {
  store.commit("setMainLoader", true);
  const params = {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log(queryString, urlParams);
  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  if (urlParams.get("salon_id")) {
    store.state.salon_id = +params.salon_id;
    store.dispatch("auth/registraion").then(() => {
      Promise.all([
        store.dispatch("tarif/getAllTariffs"),
        store.dispatch("segments/getSegmentsTable"),
        store.dispatch("tarif/getCurrentTarif"),
      ]).finally(() => {
        store.commit("setMainLoader", false);
        if (!store.state.tarif?.currentTarif?.active) {
          router.push("/tariffs");
        }
      });
    });
  }
});
</script>

<style src="./assets/fonts/stylesheet.css"></style>
<style src="./assets/reset.css"></style>
<style lang="scss">
@import "@/assets/variables.scss";

.main-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url(~@/assets/images/main-loader.svg) no-repeat center / cover;
  filter: drop-shadow(0px 0px 15px #cfcfcf);
}

.small-loader {
  width: 20px;
  height: 20px;
  background: url(~@/assets/images/small-loader.svg) no-repeat center / cover;
  filter: drop-shadow(0px 0px 15px #cfcfcf);
}

.container {
  max-width: 1610px;

  @media (max-width: 1500px) {
    width: 1500px;
    overflow: auto;
  }

  &._loader {
    filter: blur(10px);
    pointer-events: none;
  }
}

body {
  font-family: Graphik LCG;
}
.tit-h1 {
  font-size: 32px;
}
.tit-h2 {
  font-size: 26px;
}
.tit-h3 {
  font-size: 20px;

  &.bold {
    font-weight: 500;
  }
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
}
.btn {
  background: $yellowBtnBg;
  padding: 16px 25px;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  transition: 0.1s;

  &._border-raduis-9px {
    border-radius: 9px;
  }

  &._opacity {
    background: #ffe4aa;
    font-weight: 400;
  }

  

  &._gray {
    background: #f7f9fc;
  }
  &._blue {
    background: $blueBtnBg;
  }
  &._green {
    background: $greenBtnGb;
  }
  &._red {
    background: $redBtnBg;
  }

  &._small {
    padding: 10px 12px;
    border-radius: 7px;
  }

  &:hover {
    background: #fffaed;
  }
}
.text-yellow {
  color: $yellowBtnBg;
}
.link {
  cursor: pointer;
  color: $yellowBtnBg;
  text-decoration: none;
  font-weight: 500;
}

.fw-600 {
  font-weight: 500 !important;
}
.fs-18px {
  font-size: 18px;
}

.default-input {
  border: solid 1px #bdbdbd;
  border-radius: 6px;
  font-size: 11px;
  padding: 5px 16px;
  color: #71757f;
  min-height: 32px;
}
.text-small {
  color: #71757f;
  font-size: 13px;
}
.fw-500 {
  font-weight: 500;
}
</style>
